import React from "react";
import Helmet from "react-helmet";
//import { graphql } from "gatsby";
import { css } from "@emotion/core";

import MainLayout from "../components/main-layout";
import { BlurbText } from "../components/styled-components";

const Services = () => {

  return (
    <MainLayout>
      <Helmet>
        <title>FE - Services</title>
        <meta
          name="description"
          content="Services Formation Energy Provides"
        />
      </Helmet>
      <div
        css={css`
          width: 100%;
          height: 60vh;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <BlurbText size="1.5rem">Coming Soon!</BlurbText>
      </div>
    </MainLayout>
  );
};

export default Services;